<template>
  <div id="app">
    <template>
      <!-- <transition name="router-fade" mode="out-in"> -->
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
      <!-- </transition> -->
    </template>
    <!-- <template v-if="!$route.meta.keepAlive">
      22222222222
      <transition name="router-fade" mode="out-in">
        <router-view />
      </transition>
    </template> -->
    <!-- <transition name="router-fade" mode="out-in">
      <router-view v-if="!$route.meta.keepAlive" />
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
    </transition> -->
  </div>
</template>

<script>
//import layout from './components/layout'
import { login } from "@/api/sys";
export default {
  components: {},
  data() {
    return {
      url: "",
    };
  },
  mounted() {
    document.documentElement.style.fontSize = "2.6667vw";
    // 退出你之前的登录
    this.$store.state.loginUser = {};
    localStorage.removeItem("loginUser");
    localStorage.removeItem("wflow-token");
    let path = window.location.href;

    console.log("path```" + path);

    localStorage.setItem("path", path);
    //let query = path.substring(path.indexOf("params=") + 7);
    let query = path.substring(path.indexOf("token=") + 9, path.length - 3);
    console.log("path`截取完``" + query);
    //let queryJson = JSON.parse(decodeURIComponent(query));
    //  console.log("`可以吗``"+queryJson+"字符串的"+JSON.stringify(queryJson))

    // console.log(queryJson.token)
    //localStorage.setItem("wflow-token", queryJson.token);
    localStorage.setItem("wflow-token", query);
    // console.log("存完token了期望"+localStorage.getItem('wflow-token'))
    //获取完携带信息了

    //登录小程序过来的用户
    login()
      .then((rsp) => {
        let user = {
          id: rsp.data.userId,
          name: rsp.data.userName,
          avatar: rsp.data.avatar,
          position: rsp.data.position,
          type: "user",
        };
        this.$store.state.loginUser = user;
        localStorage.setItem("loginUser", JSON.stringify(user));
        //  localStorage.setItem("wflow-token", rsp.data.token);
        //  window.navigation.reload()
      })
      .catch((e) => {
        this.$err(err, "用户信息接口失败");
      });
  },
  methods: {
    send() {},
  },
};
</script>
<style lang="less">
.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 28vh;
  .img {
    width: 30vw;
  }
  .txt {
    margin-top: 1.3rem;
    color: #999;
    font-size: 1.4rem;
  }
}
.myBClass {
  width: 80vw !important;
  font-size: 1rem;
}
:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  width: 100%;

  background-color: #fff;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}

body,
html {
  margin: 0;
  height: 100%;
  width: 100vw;
}

ul {
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
  }
}

.item-desc {
  font-size: small;
  color: #5e5e5e;
}

.max-fill {
  width: 100% !important;
}

::-webkit-scrollbar {
  width: 0;
  height: 10px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  width: 0;
  height: 10px;
  border-radius: 16px;
  background-color: #d9d9d9;
}

/*/deep/ .el-message {
  background: white !important;
  padding: 5px !important;
}*/
// /deep/ .el-button--danger.is-plain{
// color: #006C50 !important;
//     background: #EEF5F3 !important;
//     border-color: #006C50 !important;
// }
</style>
