import { render, staticRenderFns } from "./WDialog.vue?vue&type=template&id=52eceb3e&scoped=true"
import script from "./WDialog.vue?vue&type=script&lang=js"
export * from "./WDialog.vue?vue&type=script&lang=js"
import style1 from "./WDialog.vue?vue&type=style&index=1&id=52eceb3e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52eceb3e",
  null
  
)

export default component.exports